<template>
  <v-row>
    <v-container fluid>
      <v-row>

         <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Código</strong></label>
          <v-text-field
            ref="code"
            v-model="partner.code"
            prepend-icon="mdi-account"
            label="Código"
            required
          />
        </v-col>

         <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Tipo de Socio</strong></label>
          <v-select
            v-model="partner.partner_type_id"
            :items="partner_types"
            item-value="id"
            item-text="name"
            prepend-icon="mdi-clipboard-file-outline"
            label="Tipo de socio"
            required
          />
        </v-col>

        <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Nombres</strong></label>
          <v-text-field
            ref="names"
            v-model="partner.names"
            prepend-icon="mdi-account"
            label="Nombres"
            required
          />
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Apellidos</strong></label>
          <v-text-field
            ref="last_name"
            v-model="partner.last_name"
            prepend-icon="mdi-account"
            label="Apellido paterno"
            required
          />
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Tipo de documento</strong></label>
          <v-select
            v-model="partner.document_id"
            :items="documents"
            item-value="id"
            item-text="name"
            prepend-icon="mdi-clipboard-file-outline"
            label="Tipo de documento"
          />
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Número de documento</strong></label>
          <v-text-field
            ref="documentNumber"
            v-model="partner.document_number"
            prepend-icon="mdi-clipboard-file-outline"
            label="Número de documento"
          />
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Fecha de nacimiento</strong></label>
          <v-menu
            v-model="partner.menu"
            :close-on-content-click="false"
            readonly
            transition="scale-transition"
            max-width="290px"
            min-width="290px"
          >
            <template #activator="{ on,attrs }">
              <v-text-field
                ref="birthday"
                v-model="partner.birth_date"
                prepend-icon="mdi-cake-variant"
                label="Fecha de nacimiento"
                readonly
                class="py-3"
                dense
                persistent-hint
                v-on="on"
                v-bind="attrs"
                @blur="date = parseDate(partner.birthday)"
              />
            </template>
            <v-date-picker
              v-model="partner.date"
              no-title
              @input="
                partner.menu = false;
                partner.birth_date = formatDate(partner.date);
              "
            />
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Teléfono celular</strong></label>
          <v-text-field
            ref="phone"
            type="text"
            v-model="partner.phone"
            prepend-icon="mdi-phone-outline"
            label="Teléfono celular"
          />
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Email</strong></label>
          <v-text-field
            ref="email"
            v-model="partner.email"
            prepend-icon="mdi-email-mark-as-unread"
            label="Email"
            required
          />
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <label for=""><strong>Dirección</strong></label>
          <v-text-field
            ref="direction"
            v-model.trim="partner.address"
            prepend-icon="mdi-map-marker-multiple"
            label="Dirección"
          />
        </v-col>
        <v-col cols="12" sm="12" md="10">
          <label for=""><strong>Referencia</strong></label>
          <v-text-field
            ref="reference"
            v-model="partner.reference"
            prepend-icon="mdi-map-marker-multiple"
            label="Referencia"
            required
          />
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Departamento</strong></label>
          <v-select
            v-model="partner.department_id"
            :items="departments"
            item-value="id"
            item-text="name"
            prepend-icon="mdi-map-marker"
            label="Departamento"
          />
        </v-col>

        <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Provincia</strong></label>
          <v-select
            v-model="partner.province_id"
            :items="provinces"
            item-value="id"
            item-text="name"
            prepend-icon="mdi-map-marker"
            label="Provincia"
          />
        </v-col>

        <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Distrito</strong></label>
          <v-select
            v-model="partner.district_id"
            :items="districts"
            item-value="id"
            item-text="name"
            prepend-icon="mdi-map-marker"
            label="Distrito"
          />
        </v-col>

        <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Profesión</strong></label>
          <v-text-field
            ref="profession"
            v-model.trim="partner.profession"
            prepend-icon="mdi-tools"
            label="Profesion"
          />
        </v-col>

        <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Lugar de trabajo</strong></label>
          <v-text-field
            ref="workplace"
            v-model.trim="partner.workplace"
            prepend-icon="mdi-toolbox-outline"
            label="Lugar de trabajo"
          />
        </v-col>

        <v-col cols="12" sm="12" md="4">
          <label for=""><strong>Sueldo</strong></label>
          <v-text-field
            ref="salary"
            v-model.trim="partner.salary"
            prepend-icon="mdi-toolbox-outline"
            label="Sueldo"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid>
      <v-row class="fill-height">
        <foto-upload :photo_url="partner.photo_url">
          <label slot="title"><strong>Foto del Socio</strong></label>
        </foto-upload>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row class="fill-height">
        <Files  :files="partner.files" :module="module">
          <label slot="title"><strong>Archivos</strong></label>
        </Files>
      </v-row>
    </v-container>
  </v-row>
</template>

<script>
import FotoUpload from "@/components/util/Foto.vue";
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import Files from "@/components/util/File.vue";

export default {
  name: "form-partner",
  props: ["partner"],
  components: { FotoUpload, Files },
  watch: {
    "partner.department_id"(value) {
      this.fetchProvinces({ department_id: value });
    },
    "partner.province_id"(value) {
      this.fetchDistricts({ province_id: value });
    },
  },
  validations() {
    return {
      partner: {
        names: required,
        last_name: required,
        document_id: required,
        document_number: required,
        birth_date: required,
        phone: required,
        email: required,
        address: required,
        reference: required,
      },
    };
  },
  data: () => ({
    module: 'partner'
  }),
  computed: {
    ...mapState({
      documents: (state) => state.util.documents,
      departments: (state) => state.util.departments,
      provinces: (state) => state.util.provinces,
      districts: (state) => state.util.districts,
      partner_types: (state) => state.util.partner_types,
    }),
    
  },
  methods: {
    ...mapActions([
      "fetchDocuments",
      "fetchDepartments",
      "fetchProvinces",
      "fetchDistricts",
      "fetchPartnerTypes"
    ]),
    getFetch() {
      this.fetchDocuments();
      this.fetchDepartments();
      this.fetchPartnerTypes();
      if (this.partner.department_id)
        this.fetchProvinces({ department_id: this.partner.department_id });
      if (this.partner.provinces_id)
        this.fetchDistricts({ province_id: this.partner.provinces_id });
    },
  },
  created() {
    this.getFetch();
  },
};
</script>

<style scoped>
</style>