<template>
  <v-container fluid class="ml-10">
    <br /><br />
    <v-row dense id="rowAddEdit">
      <v-col cols="12">
        <h1 class="mr-8" v-if="id == null">
          <v-btn fab dark small color="primary" @click="$router.go(-1)">
            <v-icon>mdi-keyboard-return</v-icon>
          </v-btn>
          Agregar Socio
        </h1>

        <h1 class="mr-8" v-if="id != null">
          <v-btn fab dark small color="primary" @click="$router.go(-1)">
            <v-icon>mdi-keyboard-return</v-icon>
          </v-btn>
          Editar Socio
        </h1>
      </v-col>

      <v-col cols="12">
        <br /><br />
        <form-partner :partner="ipartner"></form-partner>
      </v-col>

      <v-col cols="12" class="text-center">
        <br />
        <br />
        <v-btn class="button-save" color="primary" dark dense @click="save()">
          Guardar
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="color" class="mr-2" top>
      <span class="d-flex justify-center white--text">{{ text }}</span>
    </v-snackbar>
  </v-container>
</template>



<script>
import FormPartner from "@/components/forms/FormPartner.vue";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "PartnerAddEdit",
  props: ["id"],
  components: { FormPartner },
  watch: {
    partnerSusccesMessage: function (value) {
      if (value) {
        this.mensaje(true, "success", this.partnerSusccesMessage, 5000);
        if (!this.id) this.ipartner = {};
        this.$router.go(-1);
      }
    },
    errorMaxFotos: function (value) {
      if (value) this.mensaje(true, "error", value, 5000);
    },
    partnerError: function (value) {
      if (value)
        this.mensaje(true, "error", this.partnerError.errorMessage, 5000);
    },
    partner: function (value) {
      if (this.id) {
        this.ipartner = Object.assign({}, value);
      } else {
        this.ipartner = Object.assign({ ...this.ipartner }, { ...value });
      }
    },
    photo_url: function (value) {
      this.ipartner = Object.assign({}, { ...this.ipartner, photo_url: value });
    },
    // fileAdd(value) {
    //   // this.ipartner = Object.assign(this.ipartner, { ...value })
    //   console.log(value);
    // },
    fileDelete(value) {
      this.ipartner = Object.assign({}, this.partner);
    },
  },
  computed: {
    ...mapState({
      errorMaxFotos: (state) => state.util.errorMaxFotos,
      // fetchingData: (state) => state.fetchingData,
      partnerError: (state) => state.partner.partnerError,
      partnerSusccesMessage: (state) => state.partner.partnerSusccesMessage,
      partner: (state) => state.partner.partner,
      photo_url: (state) => state.util.photo_url,
      fileDelete: (state) => state.util.fileDelete,
      fileAdd: (state) => state.util.fileAdd,
    }),
  },
  data: () => ({
    ipartner: {
      // files: []
      // names:"socio",
      // last_name: "30",
      // document_id:1,
      // document_number: "72391030",
      // birth_date: "1996-11-21",
      // phone: "940393755",
      // address:"Ventanilla",
      // reference: "Costado municipalidad",
      // email: "socio14@startapps.com.pe"
    },
    snackbar: false,
    color: "",
    text: "",
  }),
  methods: {
    ...mapActions(["addPartner", "editPartner", "getPartnerById"]),
    save() {
      if (this.id) {
        //update
        this.editPartner({ partner: this.ipartner });
      } else {
        this.addPartner({ partner: this.ipartner });
        //create
      }
    },
    mensaje(snackbar, color, text) {
      this.snackbar = snackbar;
      this.color = color;
      this.text = text;
    },
    getFetch() {
      if (this.id) {
        this.getPartnerById({ id: this.id });
      }
    },
  },
  created() {
    this.getFetch();
  },
};
</script>

<style scoped>
#rowAddEdit {
  padding-right: 80px !important;
}
</style>